<script>
import { mapGetters } from "vuex";
import { wpService } from "@/services/wp";
import listingBox from "@/components/listing-box-new.vue";
import inputLocation from "@/components/field-location.vue";
export default {
  components: {
    listingBox,
    inputLocation,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
      value: null,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    ...mapGetters("config", ["get_location"]),
    location() {
      return this.get_location(this.entry.ltg_lctn);
    },
    canSubmit() {
      if (this.entry.ltg_lctn !== this.value && this.value) {
        return { location: this.value };
      }
      return false;
    },
  },
  methods: {
    reset() {
      this.value = this.entry.ltg_lctn;
    },
    submit() {
      let loading = this.$buefy.loading.open({ container: null });
      wpService.listings
        .patchSingle(this.entry.ltg_id, this.canSubmit)
        .then(() => {
          this.editing = false;
          this.$buefy.toast.open({
            message: "¡Ubicación actualizada!",
            type: "is-success",
          });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
  },
  watch: {
    editing() {
      this.reset();
    },
  },
};
</script>

<template>
  <listingBox
    title="Ubicación"
    editable
    :editing="editing"
    @editing="editing = $event"
    hasSubmit
    :canSubmit="!!canSubmit"
    @submit="submit"
  >
    <b-message v-if="!location" type="is-danger">
      <b-icon icon="exclamation-circle" />
      <span><b>Requerido:</b> Debes asignar una ubicación.</span>
    </b-message>

    <!-- <portal to="mudal" v-if="editing">
      <s-mudal :active="true" @close="editing = false">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">Ubicación</div>
          </div>
          <div class="card-content">
            <inputLocation v-model="value" />
          </div>
          <div class="card-footer">
            <b-button type="is-primary" :disabled="!canSubmit" @click="submit">
              Guardar
            </b-button>
          </div>
        </div>
      </s-mudal>
    </portal> -->

    <s-mudal v-if="editing" :active="true" @close="editing = false">
      <div class="sitioCard">
        <div class="sitioCard__header">
          <div class="sitioCard__header__title">Ubicación</div>
        </div>
        <div class="sitioCard__content">
          <inputLocation v-model="value" />
        </div>
        <div class="sitioCard__footer">
          <div class="sitioCard__footer__item">
            <b-button @click="editing = false">
              Cerrar
            </b-button>
          </div>
          <div class="sitioCard__footer__item">
            <b-button :disabled="!canSubmit" @click="submit">
              Guardar
            </b-button>
          </div>
        </div>
      </div>
    </s-mudal>

    <div v-else-if="location" class="readable">
      <b-message type="is-success">
        <b-icon icon="map-marker-alt" />
        <b>{{ location.loc_name_long }}</b>
      </b-message>
    </div>
  </listingBox>
</template>

<style lang="scss" scoped>
.sitioMudal__content {
  background-color: red;
  width: 100%;
  max-width: 40em;
}
</style>
