<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";
import _merge from "lodash/merge";
import _deburr from "lodash/deburr";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      default: null,
      type: Number,
    },
    hideSelected: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    model: {
      get() {
        let property = this.get_location(this.value);
        if (typeof property == "undefined") return null;
        return property;
      },
      set(location) {
        if (location) {
          this.$emit("input", location.loc_id);
        } else {
          this.$emit("input", null);
        }
      },
    },
    ...mapGetters("config", ["config_locations_groups", "get_location"]),
    filteredLocations() {
      if (!this.search.length) {
        return this.config_locations_groups;
      }
      let search = _deburr(this.search.toLowerCase());
      let groups = _merge([], this.config_locations_groups);
      _forEach(groups, (group, index) => {
        groups[index].group_items = _filter(group.group_items, item => {
          let name = _deburr(item.loc_name_long.toLowerCase());
          return name.indexOf(search) > -1;
        });
      });
      return groups;
    },
    textNoResult() {
      return "Oops! No se encontraron ubicaciones con estos términos de búsqueda.";
    },
  },
  methods: {
    searchChange(value) {
      this.search = value;
    },
  },
};
</script>

<template>
  <div>
    <multiselect
      v-model="model"
      :options="filteredLocations"
      :multiple="false"
      :internal-search="false"
      @search-change="searchChange"
      :hideSelected="hideSelected"
      group-values="group_items"
      group-label="group_label"
      placeholder="Escriba para buscar lugares"
      track-by="loc_id"
      label="loc_name_long"
      select-label=""
    >
      <span slot="noResult">{{ textNoResult }}</span>
    </multiselect>
    <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
  </div>
</template>
